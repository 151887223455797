import React, { useState } from "react";
import { Link } from "react-router-dom";

import BasicSLider from "../../components/BasicSlider";
import Hero from "../../components/Hero";
import Header from "../../components/Header";
import Statistics from "../../components/Statistics/indes";
import Insights from "../../components/Insights";
import ContactForm from "../../components/ContactForm";
import Footer from "../../components/Footer";

import "./styles.css";
import ParticlesComponent from "../../components/Particles";
import BrandSlider from "../../components/BrandSlider";
import Expertise from "../../components/Expertise";
import ProgressBar from "../../components/ProgressBar";
import LiveChat from "../../components/LiveChat";
import OurClients from "../../components/OurClients";
import GoogleMapLocation from "../../components/GoogleMapLocation";
import CopyrightComponent from "../../components/Copyright";

const Landing = () => {
  return (
    <>
      <div className="partcles_container">
        <ParticlesComponent />
      </div>
      <div className="hero_container">
        <Hero />
      </div>
      <div className="featured">
        <Link to="/featured" style={{ textDecoration: "none" }}>
          <h1 className="glowing-text">Check our featured products!</h1>
        </Link>
      </div>
      <ProgressBar />
      <Header />
      <BasicSLider />
      <Expertise />
      <Statistics />
      <BrandSlider />
      <Insights />
      <OurClients />
      <ContactForm />
      <GoogleMapLocation />
      <Footer />
      <CopyrightComponent />

      <LiveChat />
    </>
  );
};

export default Landing;
