import React from "react";
import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";
import "./App.css";

import BodyComponent from "./components/BodyComponent";

import Landing from "./pages/Landing";
import Test from "./Test";
import EntryPoint from "./EntryPoint";
import Product from "./pages/Product";
import ProductComponent from "./components/Product";
import ProductDetails from "./components/ProductDetails";
import { Dashboard } from "@mui/icons-material";
import AdminDashboard from "./Admin/Dashboard";
import ManageProduct from "./Admin/ManageProduct";
import ScrollToTop from "./components/ScrollToTop";
import Featured from "./pages/Featured";
import FeaturedComponent from "./pages/Featured";
import ProductDetails2 from "./components/ProductDetails/index2";

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Landing />} />

        <Route path="category" element={<Product />}>
          <Route path=":categoryId" element={<ProductComponent />} />
          <Route path=":categoryId/:brand" element={<ProductComponent />} />
        </Route>

        <Route path="product/:productId" element={<ProductDetails />} />
        <Route path="/featured" element={<FeaturedComponent />} />
        <Route path="/featured/:productId" element={<ProductDetails2 />} />

        <Route path="admin" element={<AdminDashboard />}>
          <Route path="product" element={<ManageProduct />} />
        </Route>
      </Routes>
    </Router>
  );
};
export default App;
