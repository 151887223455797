import React, { useState, useEffect } from "react";
import "./styles.css";
import LoadingComponent from "../../components/Loading";
import ProductCard from "../../components/ProductCard";
import CategoryMenu from "../../components/CategoryMenu";
import CopyrightComponent from "../../components/Copyright";

import axios from "axios";

const FeaturedComponent = () => {
  const [products, setProducts] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchProducts = async () => {
    try {
      setLoading(true);
      setProducts([]);
      let url = `${process.env.REACT_APP_API_URL}/getFeaturedProducts`;

      let response = await axios.get(url);

      setProducts(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <>
      <CategoryMenu />
      <div className="featured_area">
        <div className="container">
          <div className="row product_container">
            {loading ? <LoadingComponent /> : <></>}
            {products && products.length > 0 ? (
              products.map((element) => {
                return (
                  <div className="col-md-3">
                    <ProductCard
                      productId={element.id}
                      imageLink={
                        process.env.REACT_APP_GALLERY_URL + "/" + element.image
                      }
                      title={element.title}
                    />
                  </div>
                );
              })
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                No products
              </div>
            )}
          </div>
        </div>
      </div>
      <CopyrightComponent />
    </>
  );
};

export default FeaturedComponent;
