import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";

import "./styles.css";
import SingleProductMini from "../SingleProductMini";
import CategoryMenu from "../CategoryMenu";

const ProductDetails2 = () => {
  const { productId } = useParams();

  const [product, setProduct] = useState({});
  const [products, setProducts] = useState([]);

  useEffect(() => {}, []);

  return (
    <>
      <div>
        <CategoryMenu />
        <br />
        <br />
        <br />
        <section className="py-5">
          <div className="container">
            <div className="row gx-5">
              <aside className="col-lg-6">
                <div
                  className="border rounded-4 mb-3 d-flex justify-content-center"
                  style={{
                    marginTop: "5vh",
                  }}
                >
                  <a
                    data-fslightbox="mygalley"
                    className="rounded-4"
                    target="_blank"
                    data-type="image"
                    href="https://zenithsafetysolution.com/featured/1.png"
                  >
                    <img
                      style={{
                        marginTop: "5vh",
                        padding: "5vh",
                        width: "25vw",
                      }}
                      className="rounded-4 fit"
                      src="https://zenithsafetysolution.com/featured/1.png"
                    />
                  </a>
                </div>
              </aside>
              <main className="col-lg-6">
                <div className="ps-lg-3">
                  <h4 className="title text-dark">{product.title}</h4>
                  <br />
                  <div
                    className="order"
                    style={{
                      margin: "2vw 2vw",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: "#3b81b1", // Green color
                        color: "#fff", // White text
                        fontSize: "16px", // Font size
                        padding: "10px 20px", // Padding around the text
                        border: "none", // Removing the default border
                        borderRadius: "5px", // Rounded corners
                        cursor: "pointer", // Pointer cursor on hover
                        fontWeight: "bold", // Bold text
                        transition: "background-color 0.3s ease", // Smooth background color transition on hover
                      }}
                    >
                      <a
                        href={`tel:+8801710494433`} // Using the 'tel' protocol to initiate a call
                        style={{
                          textDecoration: "none", // Removing underline
                          color: "#fff", // Making the link text white
                        }}
                      >
                        Call for Order
                      </a>
                    </button>
                    <p className="pt-2">+8801710494433</p>
                  </div>
                  <div
                    className="row"
                    style={{
                      margin: "5px 1vw",
                      padding: "5px 1vw",
                    }}
                  >
                    <dt className="col-3">Brand</dt>
                    <dd className="col-9">Honeywell</dd>
                  </div>
                  <br />
                  <div className="description">
                    <div
                      style={{
                        fontFamily: "Arial, sans-serif",
                        margin: "5px 1vw",
                        padding: "5px 1vw",
                        backgroundColor: "#f9f9f9",
                        borderRadius: "8px",
                      }}
                    >
                      <h2
                        style={{
                          fontSize: "24px",
                          fontWeight: "bold",
                          color: "#333",
                          marginBottom: "20px",
                        }}
                      >
                        Product Description
                      </h2>
                      <p
                        style={{
                          fontSize: "14px",
                          lineHeight: "1.6",
                          color: "#555",
                          marginBottom: "15px",
                        }}
                      >
                        The Honeywell 1035106-VS Earmuff is a state-of-the-art
                        hearing protection device engineered for professionals
                        who work in environments with excessive noise levels.
                        Designed to meet the highest safety standards, this
                        earmuff provides reliable noise reduction while ensuring
                        unparalleled comfort for prolonged usage. Ideal for
                        workplaces such as construction sites, manufacturing
                        plants, airports, and oil refineries, this earmuff is
                        tailored for both durability and ease of use, making it
                        a trusted choice for professionals in Bangladesh's
                        fast-growing industrial sectors. Whether you're exposed
                        to continuous machinery noise or sudden loud bursts, the
                        Honeywell 1035106-VS is an essential tool for protecting
                        your hearing and maintaining focus on the job. With its
                        lightweight design, foldable structure, and
                        user-friendly adjustments, this earmuff seamlessly
                        combines performance, style, and comfort.
                      </p>
                    </div>
                  </div>
                  <br />
                  <hr />
                </div>
              </main>
            </div>
          </div>
        </section>
        {/* content */}
        <section className="bg-light border-top py-4">
          <div className="container">
            <div className="row gx-4">
              <div className="col-lg-8 mb-4">
                <div className="border rounded-2 p-5 bg-white">
                  {/* Pills navs */}

                  {/* Pills content */}
                  <div className="tab-content" id="ex1-content">
                    <div
                      className="tab-pane fade show active"
                      id="ex1-pills-1"
                      role="tabpanel"
                      aria-labelledby="ex1-tab-1"
                    >
                      <div className="bottom_content">
                        <div
                          style={{
                            fontFamily: "Arial, sans-serif",
                            margin: "20px",
                            padding: "10px",
                            backgroundColor: "#f9f9f9",
                            borderRadius: "8px",
                          }}
                        >
                          <h2
                            style={{
                              fontSize: "24px",
                              fontWeight: "bold",
                              color: "#333",
                              marginBottom: "10px",
                            }}
                          >
                            Key Features and Benefits
                          </h2>
                          <ul
                            style={{
                              marginLeft: "20px",
                              fontSize: "16px",
                              color: "#333",
                            }}
                          >
                            <li style={{ marginBottom: "10px", color: "#555" }}>
                              Exceptional Noise Reduction: The earmuff features
                              a Noise Reduction Rating (NRR) of 27 dB, providing
                              a significant reduction in hazardous noise levels.
                              This makes it an excellent choice for industrial
                              environments with heavy machinery, loud tools, or
                              construction work.
                            </li>
                            <li style={{ marginBottom: "10px", color: "#555" }}>
                              All-Day Comfort: The Honeywell 1035106-VS is
                              equipped with soft, high-quality ear cushions that
                              reduce pressure on the ears, ensuring comfort even
                              during extended work shifts. The adjustable
                              headband accommodates different head sizes for a
                              secure and personalized fit.
                            </li>
                            <li style={{ marginBottom: "10px", color: "#555" }}>
                              Durable Build for Tough Conditions: Manufactured
                              using high-grade plastic and foam, this earmuff is
                              designed to endure harsh environments, ensuring
                              long-lasting performance. Its rugged construction
                              guarantees reliability even in demanding
                              industrial settings.
                            </li>
                            <li style={{ marginBottom: "10px", color: "#555" }}>
                              Ventilated Design: Built with a lightweight
                              structure, the earmuff minimizes heat buildup,
                              making it suitable for hot and humid conditions in
                              Bangladesh.
                            </li>
                            <li style={{ marginBottom: "10px", color: "#555" }}>
                              Compact and Portable: The foldable design allows
                              for easy storage and transportation, making it
                              convenient for professionals on the move.
                            </li>
                            <li style={{ marginBottom: "10px", color: "#555" }}>
                              Standards Compliance: The product is certified
                              under international safety standards, including EN
                              352-1 and ANSI S3.19, ensuring its reliability and
                              effectiveness.
                            </li>
                          </ul>

                          <h2
                            style={{
                              fontSize: "24px",
                              fontWeight: "bold",
                              color: "#333",
                              marginTop: "20px",
                            }}
                          >
                            Technical Specifications
                          </h2>
                          <ul
                            style={{
                              marginLeft: "20px",
                              fontSize: "16px",
                              color: "#333",
                            }}
                          >
                            <li style={{ marginBottom: "10px", color: "#555" }}>
                              Model: 1035106-VS
                            </li>
                            <li style={{ marginBottom: "10px", color: "#555" }}>
                              Noise Reduction Rating (NRR): 27 dB
                            </li>
                            <li style={{ marginBottom: "10px", color: "#555" }}>
                              Weight: Lightweight for ease of wear
                            </li>
                            <li style={{ marginBottom: "10px", color: "#555" }}>
                              Material: High-quality plastic and sound-dampening
                              foam
                            </li>
                            <li style={{ marginBottom: "10px", color: "#555" }}>
                              Color: Black and yellow, with an ergonomic design
                            </li>
                            <li style={{ marginBottom: "10px", color: "#555" }}>
                              Adjustability: Flexible headband for a customized
                              fit
                            </li>
                          </ul>

                          <h2
                            style={{
                              fontSize: "24px",
                              fontWeight: "bold",
                              color: "#333",
                              marginTop: "20px",
                            }}
                          >
                            Applications
                          </h2>
                          <ul
                            style={{
                              marginLeft: "20px",
                              fontSize: "16px",
                              color: "#333",
                            }}
                          >
                            <li style={{ marginBottom: "10px", color: "#555" }}>
                              Construction Sites: Reduce exposure to heavy
                              machinery noise.
                            </li>
                            <li style={{ marginBottom: "10px", color: "#555" }}>
                              Factories and Manufacturing Units: Safeguard your
                              hearing from the constant hum of industrial
                              equipment.
                            </li>
                            <li style={{ marginBottom: "10px", color: "#555" }}>
                              Oil and Gas Plants: Stay protected in high-decibel
                              operational zones.
                            </li>
                            <li style={{ marginBottom: "10px", color: "#555" }}>
                              Airports: Essential for ground staff working near
                              loud jet engines.
                            </li>
                            <li style={{ marginBottom: "10px", color: "#555" }}>
                              Heavy Machinery Operations: Minimize hearing
                              damage during prolonged equipment use.
                            </li>
                          </ul>
                          <br />

                          <h2
                            style={{
                              fontSize: "24px",
                              fontWeight: "bold",
                              color: "#333",
                              marginTop: "20px",
                            }}
                          >
                            Pricing and Availability
                          </h2>
                          <br />
                          <p
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            Price: BDT 1,650 per piece (VAT inclusive).
                          </p>
                          <p
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            Availability: Always in stock and available for
                            delivery across Bangladesh.
                          </p>
                          <p
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            Bulk Orders: Special pricing is available for
                            corporate clients or large orders. Contact us for
                            more details.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Pills content */}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="px-0 border rounded-2 shadow-0">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Similar items</h5>
                      <br />
                      {products &&
                        products.map((element) => {
                          return (
                            <div className="">
                              <SingleProductMini
                                productId={element.id}
                                image={
                                  process.env.REACT_APP_GALLERY_URL +
                                  "/" +
                                  element.image
                                }
                                title={element.title}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ProductDetails2;
